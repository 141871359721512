import * as React from 'react';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import PlayIcon from '../../images/play-icon-white.svg';
import '../../styles/nutrients/magnesium.scss';

const Magnesium = () => {
  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
    light:
      'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/phosphorus-plant-proactive-min.png',
    playing: true,
    controls: true,
    width: '100%',
  };

  return (
    <Layout>
      <SEO
        title="Magnesium Fertilizer Benefits | Crystal Green | Ostara"
        description="Learn why Magnesium is a critical nutrient for your yield and how Crystal Green fertilizers provide plants with optimal magnesium for plant health."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Magnesium Fertilizer"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box w={['100%', '100%', '100%', '46.5%']}>
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Magnesium</h1>
                  <p style={{ maxWidth: 550 }}>
                    Magnesium is a critical for photosynthesis and carbohydrate
                    accumulation. Crystal Green fertilizers provide plants with
                    magnesium to optimize these critical processes.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/magnesium-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="magnesium-body-container">
          <Container className="magnesium-body-content-1-container" maxW={1400}>
            <Flex className="magnesium-body-content-1">
              <StaticImage
                className="hero-img"
                placeholder="transparent"
                src="../../images/magnesium-graph.png"
                alt="Ostara graphic"
                height={500}
              />
            </Flex>
            <Flex className="magnesium-body-content-1">
              <h3>Why do plants need magnesium?</h3>
              <p>
                Magnesium (Mg) is the central atom of the chlorophyll molecule,
                where light energy is captured and used to create sugars that
                drive growth and yield.
              </p>
              <p>
                Magnesium’s other major function is to support moving
                carbohydrate produced in the leaves to sink tissues such as
                seeds and tubers. Sufficient magnesium at grain filling or tuber
                bulking will result in higher yields.
              </p>
            </Flex>
          </Container>

          <Container
            className="magnesium-body-content-2-container"
            maxW={1400}
            marginTop={100}
          >
            <Flex className="magnesium-body-content-2-container--flexbox">
              <div>
                <StaticImage
                  placeholder="transparent"
                  src="../../images/icon-warning.png"
                  alt="Ostara icon"
                  width={250}
                  height={250}
                />
              </div>
              <Flex className="magnesium-body-content-2">
                <h3>What happens if plants don’t get enough magnesium?</h3>
              </Flex>
              <Flex className="magnesium-body-content-2">
                <p>
                  Corn leaves will develop chlorotic striations which later
                  become purplish and necrotic.
                </p>
                <p>
                  Other crops develop interveinal chlorosis leading to necrosis.
                </p>
              </Flex>
            </Flex>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="magnesium-body-content" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <Flex className="magnesium-body-content-2">
                <h3>Magnesium for Added Benefits</h3>
                <p>
                  Nutrient-packed Crystal Green fertilizers include magnesium in
                  every granule. All of the magnesium in Crystal Green is plant
                  available. Since Crystal Green only releases nutrients in
                  response to organic acids produced by roots, magnesium remains
                  available throughout the season to support plant development.
                  Crop growth and root development determines when and how much
                  magnesium is available.
                </p>
                <Link to="/product/crystal-green">
                  Learn more about Crystal Green<sup>®</sup>
                </Link>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/magnesium-img4-min.png"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="magnesium-data-container">
          <Container maxW={1400}>
            <h4>THE DATA</h4>
            <h2>More Data & Insights</h2>
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              className="magnesium-data-info"
              spacing={6}
            >
              <ReactPlayer className="module-video" {...config} />
              <Box className="magnesium-data-content">
                <h5>WEBINAR</h5>
                <h3>Phosphorus: A Plant’s Perspective</h3>

                <p>
                  Learn why phosphorus is essential for plant growth, the
                  agronomy of Crystal Green fertilizer and how growers benefit
                  from this new nutrient technology.
                  <br />
                  <br />
                  Presented by: Dr. Roland Leatherwood, Ostara’s Director of
                  Agronomy & Innovation, and Craig Davidson, President of Taurus
                  Ag.
                </p>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default Magnesium;
